import React from 'react';
import './NPS.css';

const NPSSelector = ({ value, onChange }) => {

    return (
        <div className="nps-container">
            <div className="nps-scale">
                {[0,1,2,3,4,5,6,7,8,9,10].map((_, index) => (
                    <div
                        key={index}
                        className={`nps-point ${_ == value ? 'selected' : ''}`}
                        onClick={() => onChange({ target: { value: _ } })}
                    >
                        {_}
                    </div>
                ))}
            </div>
            <div className="nps-description">
                <span className="nps-detectors">Unwahrscheinlich</span>
                <span className="nps-detectors"></span>
                <span className="nps-promoters">Sehr wahrscheinlich</span>
            </div>
        </div>
    );
};

export default NPSSelector;
