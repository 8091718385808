import React from "react";
import './TextArea.css';

export default function TextArea ({ field, value, onChange }) {

    return <textarea
                placeholder={field.hintText}
                value={value}
                onChange={onChange}
            />;

}
