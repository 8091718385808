import React, { useEffect, useState } from 'react';
import './Header.css';
import '../App.css';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return(
        <div className="default-app-header">
            <div className="wrapper">
                <a id="logolink" href={"/"}><img className="app-header-logo" alt="Logo" src={"/headerlogo-os-4096.png"}></img></a>
                <div className={`app-menu ${isMenuOpen ? 'open' : ''}`}>
                    <nav>
                        <ul className={isMenuOpen ? 'open' : ''}>
                            <li><a href="/">Portal</a></li>
                            <li><a href="/dashboard">Dashboard</a></li>
                            <li><a href="/mysurveys">Meine Umfragen</a></li>
                            <li><a href="/myaccount">Mein Account</a></li>
                            <li><a href="/logout">Abmelden</a></li>
                        </ul>
                    </nav>
                </div>
                <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

            </div>
        </div>
    );




}
export default Header;