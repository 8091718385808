import React, {useState, useEffect} from "react";
import Header from "../../components/SurveyHeader";
import '../../App.css';
import {useNavigate} from "react-router-dom";

const OpenSurvey = () => {

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        openSurvey();
    };

    function openSurvey(){
        var id = document.getElementById("survey_guid").value;
        let base = '/s/';
        let target = base.concat(id);
        console.log(target);
        navigate(target)
    }

    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    navigate('/sites/maintenance');
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    };

    useEffect(() => {
        checkMaintenanceMode();
    }, []);

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <h1>Umfrage öffnen</h1>
                <p>Bitte geben Sie den Code der Umfrage ein.</p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="survey_guid" id="label">Umfrage ID</label><br/>
                    <input type="text" id="survey_guid"/><br/><br/>
                    <button type="submit">Öffnen</button>
                </form>
            </div>
        </div>
    );
}

export default OpenSurvey;