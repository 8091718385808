import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";

const EditPersonalData = () => {
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        plz: "",
        country: "",
        username: "",
    });

    useEffect(() => {
        loadUserProfile();
    }, []);

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    id: account.id,
                    firstname: account.firstname,
                    lastname: account.lastname,
                    email: account.email,
                    phone: account.phone,
                    street: account.street,
                    city: account.city,
                    plz: account.plz,
                    country: account.country,
                    username: account.username,
                });
            } else {
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            console.error("Failed to load user profile", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: userInfo.phone,
                street: userInfo.street,
                city: userInfo.city,
                plz: userInfo.plz,
                country: userInfo.country,
                username: userInfo.username,
            };

            const response = await fetch(`https://api.onesurvey.de/v1/account/${userInfo.id}/setup`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                const text = await response.text();
                if (text === "E-Mail already used") {
                    setMessage(`Die E-Mail-Adresse ${userInfo.email} wird bereits von einem anderen Konto verwendet.`);
                } else if (text === "Username already used") {
                    setMessage(`Der Benutzername ${userInfo.username} wird bereits von einem anderen Konto verwendet.`);
                } else {
                    setMessage(text);
                }
                throw new Error('Fehler beim Ändern der Daten');
            }

            navigate('/myaccount');
        } catch (error) {
            setError(error);
            console.log(`Error ${error}`);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    return (
        <div className="account-edit-page">
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/personal-information`}> Persönliche Informationen anpassen</a>
                </span>
                <h1>Persönliche Informationen anpassen</h1>
                <p>Auf dieser Seite können Sie Ihre Benutzerdaten anpassen.</p>
                <form onSubmit={handleSubmit} className="form-edit">
                    <div className="form-group">
                        <label>Vorname</label>
                        <input
                            type="text"
                            value={userInfo.firstname}
                            name="firstname"
                            placeholder="Max"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Nachname</label>
                        <input
                            type="text"
                            value={userInfo.lastname}
                            name="lastname"
                            placeholder="Mustermann"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>E-Mail</label>
                        <input
                            type="email"
                            value={userInfo.email}
                            name="email"
                            placeholder="example@example.com"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Telefon-Nr:</label>
                        <input
                            type="phone"
                            value={userInfo.phone}
                            name="phone"
                            placeholder="+49 123456789"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <button type="submit" className="btn-primary">Daten ändern</button>
                        <button className="btn-secondary" onClick={()=>navigate('/myaccount')}>Abbrechen</button>
                        {message && <p className="form-message">{message}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPersonalData;
