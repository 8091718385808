import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import './AddFields.css';
import EditIcon from "../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../components/UIComponents/icons/Remove";
import CloseIcon from "../../../../components/UIComponents/icons/Close";
import CheckIcon from "../../../../components/UIComponents/icons/Check";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";

const Setup_AddFields = () => {
    let { surveyCode } = useParams();
    const [fieldSettings, setFieldSettings] = useState({
        position: 0,
        title: '',
        description: '',
        hintText: ''
    });
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [fields, setFields] = useState([]);
    const [options, setOptions] = useState([]);
    const [fieldTypes, setFieldTypes] = useState([]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [isListOptionPopupOpen, setListOptionPopupOpen] = useState(false);
    const [isEditOptionPopupOpen, setEditOptionPopupOpen] = useState(false);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [isCheckoutPopupOpen, setIsCheckoutPopupOpen] = useState(false);
    const [isOptionPopupOpen, setIsOptionPopupOpen] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [currentOption, setCurrentOption] = useState(null);
    const [currentFieldOptions, setCurrentFieldOptions] = useState(null);

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchSurveyId();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {

        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            })

            console.log(response);

            if (response.ok){
                console.log("Berechtigungsprüfung erfolgreich.");
            }
            else{
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        }
        catch (error){
            setError(true);
            console.log(error)
        }

    }

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);  // Umfrage-ID setzen
            setMessage(`Umfrage-ID: ${data.id}`);
            await fetchFieldTypes();
            await fetchSurveyFields(data.id);  // Felder abrufen
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    const fetchSurveyFields = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}/fields`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFields(data);  // Felder im Zustand
            setMessage("Felder erfolgreich abgerufen.");
        } catch (error) {
            console.log("Fehler beim Abrufen der Felder:", error);
            setMessage("Fehler beim Abrufen der Felder");
        }
    };

    const fetchFieldTypes = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/fieldtypes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldTypes(data)
        } catch (error) {
            console.log("Fehler beim Abrufen der Feldtypen:", error);
        }
    };

    const removeField = async (fieldId) => {
        try {
            console.log(fieldId);
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchSurveyFields(surveyId);
        } catch (error) {
            console.log("Fehler beim Löschen des Feldes:", error);
        }
    };

    const removeOption = async (optionId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchFieldOptions(currentField);
        } catch (error) {
            console.log("Fehler beim Löschen der Option:", error);
        }
    };

    const fetchFieldOptions = async (fieldId) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/options`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setOptions(data);

            if (options.length == 0){
                setMessage('Keine Optionen für diese Feld gefunden.')
            }
            else{
                setMessage('')
            }
        } catch (error) {
            console.log("Fehler beim Abrufen der Felder:", error);
            setMessage("Fehler beim Abrufen der Felder");
        }
    };

    const openAddField = async () => {
        setIsAddPopupOpen(true);
        setIsEditPopupOpen(false);
        setListOptionPopupOpen(false);
        setEditOptionPopupOpen(false);
        setIsCheckoutPopupOpen(false);
        setIsOptionPopupOpen(false);
    };

    const openEditPopup = (fieldId) => {
        setCurrentField(fieldId);
        let fieldType = fields.find((field) => field.id === fieldId).type;
        if (fieldTypes.find((type) => type.id === fieldType).options){
            setCurrentFieldOptions(true);
        }
        else{
            setCurrentFieldOptions(false);
        }

        setIsEditPopupOpen(true);

        //Close other Popups
        setListOptionPopupOpen(false);
        setEditOptionPopupOpen(false);
        setIsAddPopupOpen(false);
        setIsCheckoutPopupOpen(false);
        setIsOptionPopupOpen(false);
    };

    const openListOptions = () => {
        //Close other Popups
        setIsAddPopupOpen(false);
        setIsEditPopupOpen(false);
        setIsCheckoutPopupOpen(false);
        setIsOptionPopupOpen(false);
        setEditOptionPopupOpen(false);

        fetchFieldOptions(currentField)
        setListOptionPopupOpen(true);
    };

    const editOption = (optionId) => {
        //Close other Popups
        setIsAddPopupOpen(false);
        setIsEditPopupOpen(false);
        setIsCheckoutPopupOpen(false);
        setIsOptionPopupOpen(false);
        setListOptionPopupOpen(false);

        setCurrentOption(optionId);
        setEditOptionPopupOpen(true);
    };

    const openAddOptionPopup = () => {
        setIsAddPopupOpen(false);
        setIsEditPopupOpen(false);
        setIsCheckoutPopupOpen(false);
        setIsOptionPopupOpen(false);
        setListOptionPopupOpen(false);

        setIsOptionPopupOpen(true);
    }

    const handleEditField = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const editData = {
                position: 0,
                title: formData.get('title'),
                description: formData.get('description'),
                hintText: formData.get('hintText')
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${currentField}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(editData));
                console.log(response);
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                fetchSurveyFields(surveyId);
                fetchFieldOptions(currentField);
                console.log("Field updated successfully");
                setIsEditPopupOpen(false);
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };


    const EditFieldPopup = ({ fieldId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Feld bearbeiten: {fields.find((field) => field.id === fieldId)?.title || 'Information not found.'}</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bearbeiten Sie die Eigenschaften dieses Feldes.</p>
                    <form onSubmit={handleEditField}>
                        <div className="spacer"></div>
                        <div>
                            <label>Titel:</label>
                            <input
                                type="text"
                                placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                                name="title"
                                required
                            />
                            <div className="spacer"></div>
                        </div>
                        <div>
                            <label>Beschreibung:</label>
                            <textarea
                                placeholder="Bitte bewerten Sie unsere Produkte & Dienstleistungen"
                                name="description"
                            />
                            <div className="spacer"></div>
                        </div>
                        <div>
                            <label>Hinweistext:</label>
                            <input
                                type="text"
                                placeholder="Dieser Text erscheint bei Textfeldern als Hinweis (vor der Eingabe)"
                                name="hintText"
                            />
                        </div>
                        <button className="btn-primary" type="submit">Änderungen abspeichern</button>
                    </form>
                    {
                        currentFieldOptions && (<button className="btn-secondary" onClick={()=>openListOptions()}>Optionen bearbeiten</button>)
                    }
                </div>
            </div>
        );
    };

    const ListOptionsPopup = ({ fieldId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Optionen bearbeiten: {fields.find((field) => field.id === fieldId)?.title || 'Information not found.'}</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bearbeiten Sie die Optionen dieses Feldes.</p>
                    <h2>Optionen zu diesem Feld:</h2>
                    <div className="option-list" id="get-options-item-list">
                        {options.map((option) => (
                            <div className="field" id="fielditem-second" key={option.id}>
                                <p>
                                    {option.title}
                                </p>
                                <div className="controls">
                                    <span onClick={()=>{editOption(option.id)}}><EditIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                    <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="add-items">
                        <button className="btn-primary" onClick={openAddOptionPopup}>Neue Option hinzufügen</button>
                    </div>
                    <p>{message}</p>
                </div>
            </div>
        );
    };


    const handleEditOption = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const editData = {
                title: formData.get('text'),
                text: formData.get('text'),
                displayed: true,
                nextField: 0
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${currentOption}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(editData));
                console.log(response);
                throw new Error('Fehler beim ändern der Daten');
            }
            else{
                fetchSurveyFields(surveyId);
                fetchFieldOptions(currentField);
                console.log("Option updated successfully");
                setEditOptionPopupOpen(false);
                openListOptions();
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    const AbortEditOptionPopup = () => {
        setEditOptionPopupOpen(false);
        openListOptions();
    }

    const EditOptionPopup = ({ optionId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Optionen bearbeiten: {options.find((option) => option.id === optionId)?.title || 'Option not found.'}</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bearbeiten Sie diese Option.</p>
                    <form onSubmit={handleEditOption}>
                        <div className="spacer"></div>
                        <div>
                            <label>Text:</label>
                            <input
                                type="text"
                                placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                                name="text"
                                required
                            />
                            <div className="spacer"></div>
                        </div>
                        <button className="btn-primary" type="submit">Änderung abspeichern</button>
                    </form>
                    <button className="btn-secondary" onClick={()=>AbortEditOptionPopup()}>Zurück</button>
                </div>
            </div>
        );
    };


    const handleAddOption = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const optionData = {
                title: formData.get('title'),
                text: "",
                displayed: true,
                nextField: 0
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${currentField}/options/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(optionData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(optionData));
                console.log(response);
                throw new Error('Fehler beim Hinzufügen der Daten');
            }
            else{
                fetchFieldOptions(currentField);
                console.log("Option added successfully");
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    const AddOptionPopup = ({ fieldId, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Optionen zu Ihrer Frage hinzufügen</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Bitte fügen Sie Optionen zu Ihrer Frage ({fields.find((field) => field.id === fieldId)?.title}) hinzu.</p>
                    <form onSubmit={handleAddOption}>
                        <div>
                            <label>Text:</label>
                            <input
                                type="text"
                                placeholder="Bitte geben Sie einen Text für diese Option ein."
                                name="title"
                                required
                            />
                        </div>
                        <button className="btn-primary" type="submit">Option hinzufügen</button>
                    </form>
                    <h2>Hinzugefügte Optionen:</h2>
                    <div className="option-list" id="add-option-item-list">
                        {options.map((option) => (
                            <div className="field" id="fielditem-second" key={option.id}>
                                <p>
                                    {option.title}
                                </p>
                                <div className="controls">
                                    <span onClick={()=>{removeOption(option.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };


    const handleAddField = async (event) => {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);
            const fieldData = {
                position: 0,
                type: formData.get('type'),
                title: formData.get('title'),
                description: formData.get('description'),
                hintText: formData.get('hintText')
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/fields/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fieldData)
            });

            if (!response.ok) {
                console.log(JSON.stringify(fieldData));
                console.log(response);
                throw new Error('Fehler beim Hinzufügen der Daten');
            }
            else{
                console.log("Data updated successfully");
                fetchSurveyFields(surveyId);

                const fieldId = await response.text();
                console.log(fieldId);

                if (fieldTypes.find((type) => type.id.toString() === fieldData.type).options){
                    setIsAddPopupOpen(false);
                    setCurrentField(fieldId);
                    fetchFieldOptions(fieldId)
                    setIsOptionPopupOpen(true);
                }
                else{
                    setIsAddPopupOpen(false);
                    setIsCheckoutPopupOpen(true);
                }
            }

        } catch (error) {
            console.log(`Error ${error}`);
        }
    };

    const AddFieldPopup = ({ onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1>Neues Feld hinzufügen</h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <p>Fügen Sie zu Ihrer Umfrage neue Felder hinzu. Bitte wählen Sie zu erst einen passenden Feldtyp aus.</p>
                    <form onSubmit={handleAddField}>
                        <select
                            className="dropdown"
                            name="type"
                            required
                        >
                            <option value="" disabled>
                                Bitte wählen Sie einen Feldtyp aus
                            </option>
                            {fieldTypes.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.description}
                                </option>
                            ))}
                        </select>
                        <div className="spacer"></div>
                        <div>
                            <label>Titel:</label>
                            <input
                                type="text"
                                placeholder="Wie zufrieden sind Sie mit unseren Dienstleistungen?"
                                name="title"
                                required
                            />
                            <div className="spacer"></div>
                        </div>
                        <div>
                            <label>Beschreibung:</label>
                            <textarea
                                placeholder="Bitte bewerten Sie unsere Produkte & Dienstleistungen"
                                name="description"
                            />
                            <div className="spacer"></div>
                        </div>
                        <div>
                            <label>Hinweistext:</label>
                            <input
                                type="text"
                                placeholder="Dieser Text erscheint bei Textfeldern als Hinweis (vor der Eingabe)"
                                name="hintText"
                            />
                        </div>
                        <button className="btn-primary" type="submit">Feld hinzufügen</button>
                    </form>
                </div>
            </div>
        );
    };

    const CheckoutPopup = ({ onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1></h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <div className="spacer2"></div>
                    <CheckIcon size={'70px'} color={'yellowgreen'} fill={"none"}/>
                    <h1>Ihre Frage wurde erfolgreich hinzugefügt.</h1>
                    <p>Ihre Frage wurde erfolgreich zu Ihrer Umfrage hinzugefügt.</p>
                    <button className="btn-primary" onClick={()=>openAddField()}>Weitere Frage hinzufügen</button>
                    <button className="btn-secondary" onClick={()=>setIsCheckoutPopupOpen(false)}>Schließen</button>
                </div>
            </div>
        );
    };

    if (error){
        return(
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header/>
            <div className="app-container">
                <h1>Felder hinzufügen und anzeigen</h1>
                <h2>Ihre Felder</h2>
                <div className="field-list">
                    {fields.map((field) => (
                        <div className="field" id="fielditem" key={field.id}>
                            <div className="fielddescription">
                                <p>{field.title}<br/><span className="secondaryText">{fieldTypes.find((type) => type.id === field.type)?.description || 'Unknown Type'}</span></p>
                            </div>
                            <div className="controls">
                                <span onClick={()=>openEditPopup(field.id)}><EditIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                <span onClick={()=>{removeField(field.id)}}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="add-items">
                    <button className="btn-primary" onClick={openAddField}>Neues Feld hinzufügen</button>
                </div>
                <p>{message}</p>
                <button className="btn-secondary" onClick={()=>navigate(`/setup/${surveyCode}/design`)}>Zurück</button>
                <button className="btn-primary" onClick={()=>navigate(`/setup/${surveyCode}/publish`)}>Weiter</button>
            </div>

            {isEditPopupOpen && (
                <EditFieldPopup
                    fieldId={currentField}
                    onClose={() => setIsEditPopupOpen(false)}
                />
            )}

            {isAddPopupOpen && (
                <AddFieldPopup
                    onClose={() => setIsAddPopupOpen(false)}
                />
            )}

            {isCheckoutPopupOpen && (
                <CheckoutPopup
                    onClose={() => setIsCheckoutPopupOpen(false)}
                />
            )}

            {isOptionPopupOpen && (
                <AddOptionPopup
                    fieldId = {currentField}
                    onClose={() => setIsOptionPopupOpen(false)}
                />
            )}

            {isListOptionPopupOpen && (
                <ListOptionsPopup
                    fieldId = {currentField}
                    onClose={() => setListOptionPopupOpen(false)}
                />
            )}

            {isEditOptionPopupOpen && (
                <EditOptionPopup
                    optionId = {currentOption}
                    onClose={() => setEditOptionPopupOpen(false)}
                />
            )}
        </div>
    );
};

export default Setup_AddFields;
