import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageStatus, setStatus] = useState('green');
    const [ip, setIP] = useState('');
    const [action, setAction] = useState('');
    const [actionText, setActionText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/';

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await getIP();
            const response = await fetch('https://api.onesurvey.de/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    ipAdress: ip,
                }),
            });

            if (response.ok) {
                const text = await response.text();
                sessionStorage.setItem('OSACCESS', text);
                navigate(from);
            } else {
                const text = await response.text();
                setMessage(text);
                setStatus('red');
                if (text === 'Please verify E-Mail-Adress') {
                    setAction('/resend-verify-code');
                    setActionText('Jetzt Account verifizieren');
                    setMessage('Bitte verifizieren Sie Ihre E-Mail-Adresse.');
                }
                else if (text == 'Account deactivated'){
                    navigate('/sites/account-deactivated');
                }
            }
        } catch (error) {
            setMessage('Fehler beim Login');
            setStatus('red');
        }
    };

    const getIP = async () => {
        try {
            const res = await fetch('https://api.ipify.org/?format=json');
            const data = await res.json();
            setIP(data.ip);
        } catch (error) {
            console.error('Error fetching the IP:', error);
        }
    };

    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    navigate('/sites/maintenance');
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    }

    useEffect(() => {
        getIP();
        checkMaintenanceMode();
    }, []);

    return (
        <div className="app-login">
            <div className="login">
                <div className="login-container">
                    <div className="login-wrapper">
                        <img
                            src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                            width="250px"
                            alt="OneSurvey Logo"
                        />
                        <h2>Bei Ihrem OneSurvey Account anmelden</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Ihre E-Mail-Adresse
                                <input
                                    type="text"
                                    name="email"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="E-Mail"
                                    required
                                />
                            </label>
                            <label>
                                Passwort
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Passwort"
                                    required
                                />
                            </label>
                            <a href="/reset-password">
                                Passwort vergessen?
                            </a>
                            <button id="loginbtn" type="submit">
                                Anmelden
                            </button>
                            <p>
                                Sie haben noch keinen OneSurvey Account?{' '}
                                <a href="/create-account"><br/>
                                    Jetzt kostenlos erstellen
                                </a>
                            </p>
                            <p className={`message ${messageStatus}`}>
                                {message} <a href={action}>{actionText}</a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
