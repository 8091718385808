import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import '../../../../App.css';
import WarningIcon from "../../../../components/UIComponents/icons/Warning";

const Setup_PublishSurvey = () => {
    let { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        authorizationCheck();
        fetchSurveyId();
        SubscriptionCheck();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {

        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            })

            console.log(response);

            if (response.ok){
                console.log("Berechtigungsprüfung erfolgreich.");
            }
            else{
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        }
        catch (error){
            setError(true);
            console.log(error)
        }

    }

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    const publishSurvey = async () =>{
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/publish`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok){
                setMessage(`Ihre Umfrage wurde erfolgreich veröffentlicht.`);
                setTimeout(() => navigate(`/setup/${surveyCode}/checkout`), 1500);
            }

        } catch (error) {
            console.log("Fehler beim Veröffentlichen:", error);
            setMessage("Fehler beim Veröffentlichen der Umfrage. Bitte versuchen Sie es erneut.");
        }
    }

    const saveAsDraft = async () =>{
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${surveyId}/hide`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok){
                setMessage(`Ihre Umfrage wurde als Entwurf gespeichert.`);
                setTimeout(() => navigate(`/mysurveys`), 1500);
            }

        } catch (error) {
            console.log("Fehler speichern als Entwurf", error);
            setMessage("Fehler beim abspeichern. Bitte versuchen Sie es erneut.");
        }
    }

    if (error){
        return(
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header/>
            <div className="app-container">
                <h1>Ihre Umfrage veröffentlichen</h1>
                <p>Ihre neue Umfrage ist noch nicht veröffentlicht und als Entwurf gespeichert. Jetzt veröffentlichen und erstes Feedback einsammeln!</p>
                <button className="btn-primary" onClick={()=>publishSurvey()}>Jetzt veröffentlichen</button>
                <button className="btn-secondary" onClick={()=>saveAsDraft()}>Als Entwurf speichern</button>
                <p>{message}</p>
            </div>
        </div>
    )

}

export default Setup_PublishSurvey;