import React, {useEffect, useState} from "react";
import Header from "../../../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import '../../../../App.css';
import './Entry.css';
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import BackIcon from "../../../../components/UIComponents/icons/Back";

const Entry = () => {
    const navigate = useNavigate();
    const { entryId } = useParams();
    const [entry, setEntry] = useState();
    const [surveyCode, setSurveyCode] = useState();
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const accessToken = sessionStorage.getItem('OSACCESS');
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const origin = params.get('origin');

    useEffect(() => {
        SubscriptionCheck();
        fetchEntryData();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const fetchEntryData = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/graph/data/entry/${entryId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                setMessage(`Der Eintrag ${entryId} konnte leider nicht geladen werden.`);
                setError(true);
                console.log(error);
            } else {
                const data = await response.json();
                setMessage(`Eintrag ${entryId} wurde erfolgreich abgerufen.`);
                setEntry(data);
                setSurveyCode(data.surveyCode);
                console.log(data);
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Der Eintrag mit dem Code {entryId} wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu anzuzeigen.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <div className="entry-container">
                    <div className="page-controls">
                        <a href={origin === 'dashboard' ? '/dashboard' : `/dashboard/${surveyCode}`}>
                            <span>
                                <BackIcon id="back-icon" size={"60px"} fill={"none"} color={"#3a3a3a"}></BackIcon>
                            </span>
                        </a>
                        <span id="breaker">
                            <p>&nbsp;</p>
                        </span>
                        <span>
                            <h1>Details</h1>
                        </span>
                    </div>
                    {entry && (
                        <div className="entry-details">
                            <div className="entry-metadata">
                                <p><strong>Eintrag-ID:</strong> {entryId}</p>
                                <p><strong>Erstellungsdatum:</strong> {entry.convertedCreationTime} Uhr</p>
                                <p><strong>Bearbeitungsdauer:</strong> {entry.convertedDuration}</p>
                                <p><strong>Kampagne:</strong> {entry.campaignTag || "Keine Kampagne zugeordnet."}</p>
                            </div>
                            <h2>Antworten</h2>
                            <div className="entry-fields">
                                {entry.data.map((field) => (
                                    <div id={field.id} key={field.id} className="field-item">
                                        <h3>{field.fieldTitle}</h3>
                                        <p>{field.data || "Keine Daten vorhanden."}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="spacer"></div>
                            <a href={origin === 'dashboard' ? '/dashboard' : `/dashboard/${surveyCode}`} id="mobile-btn" className="btn btn-primary">Details schließen</a>
                            <a href={`/data/entry/${entryId}/print`} id="mobile-btn" className="btn btn-primary">Daten ausdrucken</a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Entry;