import React, {useState, useEffect} from "react";
import {unstable_useViewTransitionState, useNavigate, useParams} from "react-router-dom";
import Header from "../../components/SurveyHeader";
import CustomHeader from "../../components/survey/CustomHeader";
import TextField from "../../components/inputForms/TextField";
import TextArea from "../../components/inputForms/TextArea";
import RatingStars from "../../components/inputForms/RatingStars";
import Checkbox from "../../components/inputForms/Checkbox";
import Dropdown from "../../components/inputForms/Dropdown";
import './Survey.css';
import '../../App.css';
import SurveyHeader from "../../components/SurveyHeader";
import NPSParent from "../../components/inputForms/NPSParent";

export default function Survey () {
    let { id } = useParams();

    const navigate = useNavigate();
    const [surveyInfo, setSurveyInfo] = useState(null);
    const [surveyFields, setSurveyFields] = useState(null);
    const [fieldValues, setFieldValues] = useState({});
    const [fieldOptions, setFieldOptions] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);


    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                // Fetch survey info
                const surveyInfoResponse = await fetch(`https://api.onesurvey.de/opensurvey/v1/surveyinfo/${id}`);
                if (!surveyInfoResponse.ok) {
                    throw new Error('Network response was not ok for survey info');
                }
                const surveyInfoResult = await surveyInfoResponse.json();
                setSurveyInfo(surveyInfoResult);

                const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfoResult.id}/statistics/visited`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                })

                // Fetch survey fields using the surveyId from the first response
                const surveyFieldsResponse = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfoResult.id}/fields/`);
                console.log(`https://api.onesurvey.de/opensurvey/v1/surveyfields/${surveyInfoResult.id}`);
                if (!surveyFieldsResponse.ok) {
                    throw new Error('Network response was not ok for survey fields');
                }
                const surveyFieldsResult = await surveyFieldsResponse.json();
                setSurveyFields(surveyFieldsResult);

                const options = await Promise.all(surveyFieldsResult.map(async (field) => {
                    const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/field/${field.id}/options`);
                    if (!response.ok) {
                        if (response.status !== 404) {
                            throw new Error('Network response was not ok for field options');
                        }
                        return { [field.id]: [] }; // Return empty array if options not found (404)
                    }
                    const result = await response.json();
                    return { [field.id]: result };
                }));

                const optionsMap = options.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                setFieldOptions(optionsMap);
                setLoading(false);
            } catch (error) {
                setError(error);
                console.log(`Error ${error}`);
                setLoading(false);
            }
        };

        const checkMaintenanceMode = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const status = await response.json();
                    if (status.value === "true"){
                        navigate('/sites/maintenance');
                    }
                }
                else{
                    console.log("Error fetching Portal APIs");
                }
            } catch (error) {
                console.log("Error fetching Portal APIs");
            }
        };

        const localStartTime = getLocalISOTime();
        setStartTime(localStartTime);
        fetchSurveyData();
        checkMaintenanceMode();
    }, [id]);

    function getLocalISOTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    const handleChange = (fieldId, value) => {
        setFieldValues(prevState => ({
            ...prevState,
            [fieldId]: value
        }));
    };

    const handleNPSChange = (fieldId, value) => {
        if (value === 0){
            value = "0";
        }
        setFieldValues(prevState => ({
            ...prevState,
            [fieldId]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = surveyFields.map(field => ({
            fieldId: field.id,
            value: String(fieldValues[field.id] || "")
        }));

        console.log(startTime)
        console.log(new Date().toISOString())
        const campaign = params.get('oscampaign') ? params.get('oscampaign').toUpperCase() : "";

        const payload = {
            startTime: startTime,
            endTime: getLocalISOTime(),
            campaignTag: campaign,
            data: data
        };

        try {
            const response = await fetch(`https://api.onesurvey.de/opensurvey/v1/survey/${surveyInfo.id}/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                console.log(JSON.stringify(payload));
                console.log(response)
                throw new Error('Netzwerkantwort war nicht ok für das Hinzufügen der Umfrage')
            }

            let base = '/s/';
            let guid = base.concat(id);
            let target = guid.concat('/checkout');
            navigate(target)
            navigate(target);
        } catch (error) {
            setError(error);
            console.log(payload);
            console.log(`Error ${error}`);
        }
    };



    const renderField = (field) => {

        document.title = surveyInfo.title;

        const fieldProps = {
            field: field,
            value: fieldValues[field.id] || '',
            onChange: (e) => handleChange(field.id, e.target.value)
        };

        const npsFieldProps = {
            field: field,
            value: fieldValues[field.id] || "0",
            onChange: (e) => handleNPSChange(field.id, e.target.value)
        };

        switch (field.type) {
            case 1:
                return <TextField field={field} {...fieldProps} />;
            case 2:
                return <TextArea field={field} {...fieldProps} />;
            case 3:
                return <RatingStars field={field} {...fieldProps} />;
            case 4:
                return <Checkbox field={field} fieldOptions={fieldOptions} {...fieldProps} />;
            case 5:
                return <Dropdown field={field} fieldOptions={fieldOptions} {...fieldProps} />
            case 8:
                return <NPSParent field={field} {...npsFieldProps} />
            default:
                return null;
        }
    };


    if (loading) {
        return (
            <div>
                <SurveyHeader></SurveyHeader>
                <div className="app-container">
                    <div>Loading...</div>
                </div>
            </div>
        )
    }

    if (error) {
        document.title = '404 Umfrage wurde nicht gefunden - OneSurvey';
        return (
            <div>
                <SurveyHeader></SurveyHeader>
                <div className="app-container">
                    <h1>404 - Not found</h1>
                    <p>Die gewünschte Umfrage mit der ID {id} konnte leider nicht gefunden werden.</p>
                    <button onClick={()=> navigate('/s')}>zurück zur Startseite</button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <CustomHeader logoUrl={surveyInfo.frontendLogo} headerBackground={surveyInfo.headerBackground}></CustomHeader>
            <div className="survey-container" style={{
                backgroundImage: `url(${surveyInfo.siteBackground})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="survey" style={{ background: `rgb(255,255,255,0.8)`}}>
                    <h1>{surveyInfo.title}</h1>
                    <p>{surveyInfo.description}</p>
                    <div className="divider"></div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            {
                                <div>
                                    {surveyFields.map(field => (
                                        <div key={field.id}>
                                            <h3>{field.title}</h3>
                                            <p>{field.description}</p>
                                            {renderField(field)}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="spacer"></div>
                        <button type="submit">Umfrage absenden</button>
                        <div className="spacer"></div>
                        <hr/>
                        <p id="datasecurity" className="secondaryText">Dieser Inhalt wird vom Besitzer des Formulars erstellt. Die von Ihnen übermittelten Daten werden an den Formularbesitzer gesendet. Die OneCoding Software Rottenbiller Schenk GbR (nachfolgend OneSurvey) ist nicht für die Datenschutz- oder Sicherheitspraktiken seiner Kunden, einschließlich derer dieses Formularbesitzers, verantwortlich. Geben Sie niemals Ihr Kennwort oder andere vertrauliche Informationen heraus.</p>
                        <a href={surveyInfo.linkHome}>Informationen zum Formularbesitzer</a><br/>
                        <a href={surveyInfo.linkImpressum}>Impressum</a>&nbsp;&nbsp;&nbsp;
                        <a href={surveyInfo.linkPrivacy}>Datenschutz</a><br/>
                        <div className="spacer"></div>
                    </form>
                </div>
            </div>
        </div>
    );

}