import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../../components/Header";
import '../EditField/EditFields.css';
import WarningIcon from "../../../../../../components/UIComponents/icons/Warning";

const EditOption = () => {
    const { surveyCode, fieldId, optionId } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [optionData, setOptionData] = useState({ title: "" });
    const [optionTitle, setOptionTitle] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        const authorizationCheck = async () => {
            try {
                const surveyResponse = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                const optionResponse = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!surveyResponse.ok || !optionResponse.ok) {
                    throw new Error("No authorization or option not found.");
                }

                const option = await optionResponse.json();
                setOptionData(option);
                setOptionTitle(option.title)
            } catch (err) {
                setError(true);
                setMessage(err.message);
            }
        };

        const SubscriptionCheck = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok){
                    throw new Error("No active OneSurvey Subscription.");
                }

            } catch (error) {
                navigate('/e-shop');
            }
        };

        authorizationCheck();
        SubscriptionCheck();
    }, [surveyCode, optionId, accessToken]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOptionData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEditOption = async (event) => {
        event.preventDefault();
        try {
            const editData = {
                title: optionData.title,
                text: optionData.title,
                displayed: true,
                nextField: 0,
            };

            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/option/${optionId}/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editData),
            });

            if (response.ok) {
                navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options`);
            } else {
                throw new Error('Error updating option');
            }
        } catch (error) {
            setMessage(error.message);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Error</h1>
                    <p>{message}</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="edit-fields-container">
                <h1>Option "{optionTitle}" bearbeiten</h1>
                <p>Bearbeiten Sie diese Option.</p>
                <form onSubmit={handleEditOption}>
                    <div className="form-group">
                        <label>Text:</label>
                        <input
                            type="text"
                            name="title"
                            value={optionData.title}
                            onChange={handleInputChange}
                            placeholder="Ihr Text für Ihre Option"
                            required
                        />
                    </div>
                    <button className="btn-primary" type="submit">Änderungen speichern</button>
                </form>
                <button className="btn-secondary" onClick={() => navigate(`/mysurveys/${surveyCode}/fields/${fieldId}/options`)}>Abbrechen</button>
            </div>
        </div>
    );
};

export default EditOption;
