import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import SelectIcon from "../../../../components/UIComponents/icons/Select";
import "../../../../App.css";

const MySubscriptions = () => {
    const navigate = useNavigate();
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchActiveSubscriptions = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/subscriptions`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Abonnements");
                } else {
                    const data = await response.json();
                    setSubscriptions(data);
                    setError(false);
                    setLoading(false);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchActiveSubscriptions();
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Abonnements nicht gefunden</h1>
                    <p>Aktuell besitzen Sie keine aktiven OneSurvey-Abonnements. Sehen Sie sich unsere Angebote an und finden Sie den passenden Tarif für Ihre Bedürfnisse!</p>
                    <button className="btn btn-primary" onClick={() => navigate('/e-shop')}>
                        Jetzt Angebote entdecken
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <div>
                    <h1>Meine Abonnements</h1>
                    <p>Hier finden Sie eine Übersicht Ihrer aktuellen Abonnements bei OneSurvey. Verwalten Sie Ihre aktiven Abonnements und sehen Sie sich die Details Ihrer Verträge an.</p>
                    <div className="spacer"></div>
                    <div className="list">
                        {
                            subscriptions.length > 0 ? (
                                subscriptions.map(subscription => (
                                    <div key={subscription.id} className="list-item">
                                        <div id="item-description">
                                            <h2>{subscription.title}</h2>
                                            <p>
                                                Laufzeit: {subscription.convertedStartDate} bis {subscription.convertedEndDate}<br />
                                                Status: {subscription.autoRenew ? "Abonnement aktiv" : "Gekündigt zum " + subscription.convertedEndDate}
                                            </p>
                                        </div>
                                        <div id="item-controls">
                                            <a href={`/myaccount/subscriptions/${subscription.id}`}>
                                                <SelectIcon size={"50px"} fill={"none"} color={"#3a3a3a"} />
                                            </a>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                                    <h2>Keine Abonnements gefunden</h2>
                                    <p>Es sind keine Abonnements verfügbar. Besuchen Sie unseren Shop, um die besten Angebote zu entdecken!</p>
                                    <button className="btn btn-primary" onClick={() => navigate('/e-shop')}>Zum Shop</button>
                                </div>
                            )
                        }
                    </div>
                </div>
                <button className="btn btn-secondary" onClick={() => navigate(`/myaccount`)}>Zurück zu Mein Konto</button>
            </div>
        </div>
    );
}

export default MySubscriptions;