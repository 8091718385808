import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";

const EditAdressInformation = () => {
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [error, setError] = useState(null);
    const [userInfo, setUserInfo] = useState({
        street: "",
        city: "",
        plz: "",
        country: "",
    });

    useEffect(() => {
        loadUserProfile();
    }, []); // The empty array ensures this runs only once after initial render

    const loadUserProfile = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const account = await response.json();
                setUserInfo({
                    id: account.id,
                    street: account.street,
                    city: account.city,
                    plz: account.plz,
                    country: account.country,
                });

            } else {
                navigate('/login');
            }
        } catch (error) {
            setError(error);
            console.error("Failed to load user profile", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                street: userInfo.street,
                city: userInfo.city,
                plz: userInfo.plz,
                country: userInfo.country,
            };

            const response = await fetch(`https://api.onesurvey.de/v1/account/${userInfo.id}/edit-adress`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                throw new Error('Fehler beim Ändern der Daten');
            }

            navigate('/myaccount');
        } catch (error) {
            setError(error);
            console.error("Error updating data", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Adressinformationen ändern</h1>
                    <p>Error: Daten konnten leider nicht geladen werden. Bitte versuchen Sie es in ein paar Minuten noch einmal.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="account-edit-page">
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/adress-information`}> Adressinformationen ändern</a>
                </span>
                <h1>Adressinformationen ändern</h1>
                <p>Auf dieser Seite können Sie Ihre Adressinformationen anpassen.</p>
                <form onSubmit={handleSubmit} className="form-edit">
                    <div className="form-group">
                        <label>Straße und Hausnummer</label>
                        <input
                            type="text"
                            value={userInfo.street}
                            name="street"
                            placeholder="Musterstraße 4"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Ort</label>
                        <input
                            type="text"
                            value={userInfo.city}
                            name="city"
                            placeholder="Musterstadt"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>PLZ</label>
                        <input
                            type="text"
                            value={userInfo.plz}
                            name="plz"
                            placeholder="12345"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <label>Land</label>
                        <input
                            type="text"
                            value={userInfo.country}
                            name="country"
                            placeholder="Deutschland"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="spacer3"></div>
                    <div className="form-group">
                        <button type="submit" className="btn-primary">Daten ändern</button>
                        <button className="btn-secondary" onClick={()=>navigate('/myaccount')}>Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditAdressInformation;
