import React from "react";
import './Checkbox.css';

export default function Checkbox ({ field, value, onChange, fieldOptions }) {

    return (
        <div>
            {fieldOptions[field.id].map(option => (
                <div className="checkbox-element">
                    <label className="checkbox-label" key={option.id}>
                        <input
                            className="checkbox-input"
                            type="checkbox"
                            value={option.id}
                            onChange={() => {
                            const newValue = value.includes(option.title) ? value.filter(v => v !== option.title) : [...value, option.title];
                            onChange({ target: { value: newValue } });
                            }}
                        />{option.title}
                    </label>
                </div>
            ))}
        </div>
    );
}
