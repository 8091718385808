import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import '../../App.css';
import PublicHeader from "../../components/PublicHeader";
import CheckIcon from "../../components/UIComponents/icons/Check";
import PublicFooter from "../../components/PublicFooter";

const Logout = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const logout = async () => {
            sessionStorage.removeItem('OSACCESS');
        };

        logout();
    }, []);



    return (
        <div>
            <PublicHeader></PublicHeader>
            <div className="app-container">
                <CheckIcon size={"80px"} fill={"none"} color={"yellowgreen"}></CheckIcon>
                <h1>Sie haben sich erfolgreich abgemeldet! Vielen Dank und einen schönen Tag.</h1>
                <p></p>
                <button onClick={()=>navigate('/')}>Zurück zur App</button>
                <div className="spacer"></div>
                <div className="divider"></div>
                <div className="spacer"></div>
                <h1>Unsere Produktempfehlungen für Sie:</h1>
                <div className="grid-dashboard">
                    <div className="card" style={{height: "auto"}}>
                        <h3>OneSurvey Integration bei Ihrer Webseite</h3>
                        <p>Wir integrieren Ihre Umfragen in Ihre bestehende Webseite. </p>
                        <a href="https://onesurvey.de/">Zum Angebot</a>
                    </div>
                    <div className="card" style={{height: "auto"}}>
                        <h3>OneSurvey OnPremise</h3>
                        <p>Maximale Datenhoheit bei Ihren Umfragen. Ihre eigene OneSurvey Instanz auf Ihren Servern!</p>
                        <a href="https://onesurvey.de/">Erfahren Sie mehr</a>
                    </div>
                    <div className="card" style={{height: "auto"}}>
                        <h3>Weitere Produkte der OneCoding</h3>
                        <p>Entdecken Sie unsere weiteren Produkte für Ihren digitalen Erfolg!</p>
                        <a href="https://onecoding.de/">Jetzt mehr erfahren</a>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="spacer1"></div>
            </div>
            <PublicFooter/>
        </div>
    );
};

export default Logout;
