import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import login from "../sites/Auth/Login/Login";

const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isValid, setIsValid] = useState(null);

    useEffect(() => {
        const validateToken = async () => {
            const accessToken = sessionStorage.getItem('OSACCESS');

            if (!accessToken) {
                navigate('/login', { state: { from: location } });
                return;
            }

            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/validate', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    setIsValid(true);
                } else {
                    setIsValid(false);
                }
            } catch (error) {
                setIsValid(false);
            }
        };

        const checkMaintenanceMode = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const status = await response.json();
                    if (status.value === "true"){
                        navigate('/sites/maintenance');
                    }
                }
                else{
                    console.log("Error fetching Portal APIs");
                }
            } catch (error) {
                console.log("Error fetching Portal APIs");
            }
        }

        validateToken();
        checkMaintenanceMode();
    }, [navigate]);

    if (isValid === null) {
        // Loading state while the validation is in progress
        return <div></div>;
    }

    return isValid ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
