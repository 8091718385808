import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './Registration.css';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const Register = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        secKey: '57648393454',
        email: '',
        firstname: '',
        lastname: '',
        password: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [messageStatus, setStatus] = useState('green');
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!captchaToken) {
            setMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
            setStatus('red');
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetch('https://api.onesurvey.de/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInfo),
            });

            const text = await response.text();
            if (!response.ok) {
                setMessage(text || 'Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.');
                setStatus('red');
            } else {
                setMessage('Registrierung erfolgreich! Sie werden in Kürze weitergeleitet.');
                setStatus('green');
                setTimeout(() => navigate('/create-account/checkout'), 3000);
            }
        } catch (error) {
            setMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
            setStatus('red');
        } finally {
            setIsSubmitting(false);
        }
    };


    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    navigate('/sites/maintenance');
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    }

    useEffect(() => {
        checkMaintenanceMode();
    }, []);


    const onCaptchaVerify = (token) => {
        setCaptchaToken(token);
    };

    return (
        <div className="app-registration">
            <div className="registration">
                <div className="registration-container">
                    <div className="registration-wrapper">
                        <img
                            src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                            width="250px"
                            alt="OneSurvey Logo"
                        />
                        <h2>Erstellen Sie Ihren OneSurvey Account</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Vorname
                                <input
                                    type="text"
                                    name="firstname"
                                    placeholder="Max"
                                    value={userInfo.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Nachname
                                <input
                                    type="text"
                                    name="lastname"
                                    placeholder="Mustermann"
                                    value={userInfo.lastname}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                E-Mail
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="max.mustermann@domain.de"
                                    value={userInfo.email}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <label>
                                Passwort
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="********"
                                    value={userInfo.password}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                            <div className="registration-aknowledge">
                                <div className="registration-aknowledge-input-labelwrapper">
                                    <label className="registration-aknowledge-label" for="aknowledge">
                                        <span>Mit der Erstellung eines Accounts stimmen Sie den <a href="https://onecoding.de/datenschutz">Datenschutzbestimmungen</a> und <a href="https://onecoding.de/agb">AGBs</a> der OneCoding Software Rottenbiller Schenk GbR zu.</span>
                                    </label>
                                </div>
                                <div className="registration-aknowledge-input-inputwrapper">
                                    <input
                                        className="registration-aknowledge-input"
                                        type="checkbox"
                                        name="aknowledge"
                                        required
                                    />
                                </div>
                            </div>
                            <HCaptcha
                                sitekey="c3122632-8252-4e70-a38c-3f2857769e9c"
                                onVerify={onCaptchaVerify}
                            />
                            <button id="loginbtn" type="submit" disabled={isSubmitting}>
                                {isSubmitting ? 'Bitte warten...' : 'Account erstellen'}
                            </button>
                            <p className={`message ${messageStatus}`}>
                                {message}
                            </p>
                        </form>
                        <a href="/login">Zurück zur Anmeldung</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
