import React, {useState, useEffect} from "react";
import Header from "../../components/TransparentHeader";
import {useNavigate} from "react-router-dom";
import Footer from "../../components/Footer";
import './Home.css';
import '../../App.css';

const Home = () => {

    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [firstname, setFirstname] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        const loadUserdata = async () => {

            if (!accessToken) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const account = await response.json();
                    console.log(account);
                    setFirstname(account.firstname);
                    setLastname(account.lastname);
                    setEmail(account.email);
                } else {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            }
        };

        loadUserdata();
    }, [navigate]);

    return (
        <div>
            <Header></Header>
            <div className="siteHero">
                <h2 className="siteTitle">Herzlich willkommen {firstname} {lastname}!</h2>
            </div>
            <div className="app-container">
                <div className="grid-dashboard">
                    <div className="card">
                        <h3>Meine Umfragen</h3>
                        <p className="secondaryText" id="grid-dashboard-card-desc">Erstellen Sie neue Umfragen oder verwalten Sie Ihre bestehenden Umfragen.</p>
                        <a href="/mysurveys">Jetzt verwalten</a>
                    </div>
                    <div className="card">
                        <h3>Dashboard</h3>
                        <p className="secondaryText" id="grid-dashboard-card-desc">Sehen Sie auf unserem Dashboard die Ergebnisse Ihrer Umfragen ein.</p>
                        <a href="/dashboard">Jetzt ansehen</a>
                    </div>
                    <div className="card">
                        <h3>Umfragen öffnen</h3>
                        <p className="secondaryText" id="grid-dashboard-card-desc">Öffnen Sie eine Umfrage</p>
                        <a href="/s">Jetzt Umfrage öffnen</a>
                    </div>
                    <div className="card">
                        <h3>Mein Account</h3>
                        <p className="secondaryText" id="grid-dashboard-card-desc">Verwalten Sie die Einstellungen Ihres OneSurvey Account.</p>
                        <a href="/myaccount">Account verwalten</a>
                    </div>
                    <div className="card">
                        <h3>OneSurvey Campaigns</h3>
                        <p className="secondaryText" id="grid-dashboard-card-desc">Erhalten Sie noch mehr Ergebnisse, mit OneSurvey Campaigns!</p>
                        <a href="/campaigns">Jetzt starten</a>
                    </div>
                </div>
                <div className="spacer"></div>
                <button className="btn-danger" onClick={()=>navigate('/logout')}>Abmelden</button>

            </div>
            <Footer></Footer>
        </div>
    );

};

export default Home;