import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import "../../../../App.css";
import "./ProductPage.css"

const ProductPage = () => {
    const { productCode } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState({
        id: 0,
        code: "",
        title: "",
        description: "",
        price: 0.00,
        status: false,
        autoRenew: false,
        type: "",
        duration: 0,
        created: "",
        updated: "",
    });
    const [endPrice, setEndPrice] = useState(0.00);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/e-shop/products/${productCode}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Produkte");
                }
                else{
                    const data = await response.json();
                    setProduct(data);
                    setError(false);
                    setLoading(false);

                    let price = data.price * data.duration;
                    let formattedPrice = price.toFixed(2).replace('.', ',');
                    setEndPrice(formattedPrice);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [productCode]);


    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Produkt wurde leider nicht gefunden.</h1>
                    <p>Das Produkt mit der ID {productCode} wurde nicht gefunden oder steht derzeit leider nicht zur Verfügung.</p>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header></Header>
            <div className="app-container">
                <div>
                    <p className="secondaryText">Artikel: {productCode}</p>
                    <h1>{product.title}</h1>
                    <strong>
                        <p style={{fontWeight: "normal", fontSize: "22px"}}>{product.price > 0 ? `${product.price},00 €` + `${product.type === "Monthly" ? " / Monat" : ""}` : "Kostenlos"}</p>
                    </strong>
                    <p>{product.description}</p>
                    <div className="spacer4"></div>
                    <div className="divider"></div>
                    <div className="spacer4"></div>
                    <h3>Produktspezifikationen:</h3>
                    <p>{product.duration > 1 ? "Laufzeit: " + product.duration + " Monate": "Laufzeit: " + product.duration + " Monat"}</p>
                    <p>{product.autoRenew ? "Das Abonnement verlängert sich nach Ablauf des Zeitraums automatisch. Die Kündigungsfrist beträgt 1 Woche vor Ende der Laufzeit." : "Das Abonnement läuft am Enddatum automatisch aus. Bitte beachten Sie, dass Sie nach Ablauf des Abonnements keinen Zugang mehr zur OneSurvey Cloud besitzen."}</p>
                    <div className="spacer"></div>
                    <button className="btn btn-primary" id="sitebtn" onClick={()=> navigate(`/e-shop/${productCode}/order`)}>Weiter</button>
                </div>
            </div>
            <div className="status-bar">
                <div className="status-bar-items">
                    <span>
                        <h2>Ihre heutige Rechnung:</h2>
                    </span>
                    <span>
                        <p className="price-tag">{endPrice} €</p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ProductPage