import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../components/Header";
import './Checkout.css'
import CheckIcon from "../../../../components/UIComponents/icons/Check";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";

const Setup_Checkout = () => {

    let { surveyCode } = useParams();
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);


    useEffect(() => {
        authorizationCheck();
        fetchSurveyId();

        let i = 0;

        for(i=0; i<200; i++) {
            // Random rotation
            var randomRotation = Math.floor(Math.random() * 360);
            // Random width & height between 0 and viewport
            var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
            var randomHeight =  Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 0));

            // Random animation-delay
            var randomAnimationDelay = Math.floor(Math.random() * 10);

            // Random colors
            var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0']
            var randomColor = colors[Math.floor(Math.random() * colors.length)];

            // Create confetti piece
            var confetti = document.createElement('div');
            confetti.className = 'confetti';
            confetti.style.top=randomHeight + 'px';
            confetti.style.left=randomWidth + 'px';
            confetti.style.backgroundColor=randomColor;
            confetti.style.transform='skew(15deg) rotate(' + randomRotation + 'deg)';
            confetti.style.animationDelay=randomAnimationDelay + 's';
            document.getElementById("confetti-wrapper").appendChild(confetti);
        }

    }, []);

    const authorizationCheck = async () => {

        try{
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            })

            console.log(response);

            if (response.ok){
                console.log("Berechtigungsprüfung erfolgreich.");
            }
            else{
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        }
        catch (error){
            setError(true);
            console.log(error)
        }

    }

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    if (error){
        return(
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="container-wrapper">
                <div className="modal">
                    <span className="emoji"><CheckIcon size={"80px"} color={"yellowgreen"} fill={"none"}/></span>
                    <h1 id="modaltitle">Ihre Umfrage wurde erfolgreich veröffentlicht!</h1>
                    <p id="modaltext">Jetzt kann es losgehen! Teilen Sie den Link und sammeln Sie wertvolle Meinungen und Feedback.</p>
                    <p id="modaltext"><strong>Link zur Umfrage</strong><br/>{`https://app.onesurvey.de/s/${surveyCode}`}</p>
                    <div className="spacer"></div>
                    <a href={`https://app.onesurvey.de/s/${surveyCode}`} className="modal-btn">Jetzt Umfrage öffnen</a>
                </div>
                <div id="confetti-wrapper">
                </div>
            </div>
        </div>
    )
}

export  default Setup_Checkout