import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../../components/Header";
import './SurveyFields.css';
import EditIcon from "../../../../../components/UIComponents/icons/Edit";
import RemoveIcon from "../../../../../components/UIComponents/icons/Remove";
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import CloseIcon from "../../../../../components/UIComponents/icons/Close";
import CheckIcon from "../../../../../components/UIComponents/icons/Check";

const SurveyFields = () => {
    const { surveyCode } = useParams();
    const [fieldSettings, setFieldSettings] = useState({
        position: 0,
        title: '',
        description: '',
        hintText: ''
    });
    const navigate = useNavigate();
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [surveyId, setSurveyId] = useState(null);
    const [surveyTitle, setSurveyTitle] = useState(null);
    const [survey, setSurvey] = useState({
        title: "",
        description: "",
        status: false,
        headerBackground: "white",
        linkHome: "",
        linkImpressum: "",
        linkPrivacy: "",
    });
    const [fields, setFields] = useState([]);
    const [fieldTypes, setFieldTypes] = useState([]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [currentField, setCurrentField] = useState(null);

    useEffect(() => {
        authorizationCheck();
        SubscriptionCheck();
        fetchSurveyId();
    }, []);

    const SubscriptionCheck = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/subscriptions/validate',{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok){
                throw new Error("No active OneSurvey Subscription.");
            }

        } catch (error) {
            navigate('/e-shop');
        }
    }

    const authorizationCheck = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}/validate`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.ok) {
                console.log("Berechtigungsprüfung erfolgreich.");
            } else {
                setError(true);
                console.log("Keine Berechtigung, diese Umfrage zu bearbeiten.");
            }
        } catch (error) {
            setError(true);
            console.log(error);
        }
    };

    const fetchSurveyId = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/setup/${surveyCode}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setSurveyId(data.id);
            await fetchFieldTypes();
            await fetchSurveyFields(data.id);
            await fetchSurveyData(data.id);
        } catch (error) {
            console.log("Fehler beim Abrufen der Umfrage-ID:", error);
            setMessage("Fehler beim Abrufen der Umfrage-ID");
        }
    };

    const fetchSurveyData = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) throw new Error("Fehler beim Abrufen der Umfrage-Daten");

            const data = await response.json();
            setSurvey({
                ...data,
                status: data.status || false, // Ensure status is boolean
            });
            setSurveyTitle(data.title);
        } catch (error) {
            console.error(error);
            setMessage("Fehler beim Abrufen der Umfrage-Daten");
        }
    };

    const fetchSurveyFields = async (id) => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/${id}/fields`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFields(data);
        } catch (error) {
            console.log("Fehler beim Abrufen der Felder:", error);
            setMessage("Fehler beim Abrufen der Felder");
        }
    };

    const fetchFieldTypes = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/fieldtypes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.json();
            setFieldTypes(data);
        } catch (error) {
            console.log("Fehler beim Abrufen der Feldtypen:", error);
        }
    };

    const removeField = async (fieldId) => {
        setPopupOpen(false);
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchSurveyFields(surveyId);
        } catch (error) {
            console.log("Fehler beim Löschen des Feldes:", error);
        }
    };

    const deactivateField = async (fieldId) => {
        setPopupOpen(false);
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/admin/survey/field/${fieldId}/deactivate`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            const data = await response.text();
            setMessage(data);
            fetchSurveyFields(surveyId);
        } catch (error) {
            console.log("Fehler beim Löschen des Feldes:", error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Keine Berechtigung</h1>
                    <p>Fehler: Die Survey mit dem Code wurde nicht gefunden oder Sie haben keine Berechtigung, diese zu bearbeiten.</p>
                </div>
            </div>
        );
    }

    const openDeleteConfirmation = (field) => {
        setCurrentField(field);
        setPopupOpen(true);
    };

    const DeletePopup = ({ field, onClose }) => {
        return (
            <div className="popup">
                <div className="popup-content">
                    <div className="popup-headline">
                        <h1></h1>
                        <span onClick={onClose}><CloseIcon size={"60px"} fill={"none"} color={"currentColor"}/></span>
                    </div>
                    <div className="spacer2"></div>
                    <WarningIcon size={'70px'} color={'red'} fill={"none"}/>
                    <h1>Achtung: Die Daten dieses Feldes werden auch entfernt!</h1>
                    <p>Wenn Sie dieses Feld löschen, werden auch die Daten dieses Feldes gelöscht. Setzen Sie es daher besser inaktiv.<br/>
                        <br/>
                        <strong>Setzen Sie das Feld einfach inaktiv. Es wird in der Umfrage nicht mehr angezeigt. Die Daten bleiben vorhanden.</strong></p>
                    <button className="btn-danger" onClick={()=>removeField(field.id)}>Feld & Daten löschen</button>
                    <button className="btn-primary" onClick={()=>deactivateField(field.id)}>Feld inaktiv setzen</button>
                    <button className="btn-secondary" onClick={()=>setPopupOpen(false)}>Abbrechen</button>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Header />
            <div className="survey-fields-container">
                <span className="app-breadcrumbs"><a href='/mysurveys'>Meine Umfragen &gt; </a><a href={`/mysurveys/${surveyCode}`}>{surveyTitle} &gt; </a><a href={`/mysurveys/${surveyCode}/fields`}>Felder & Fragen</a></span>
                <h1>Felder hinzufügen und anzeigen</h1>
                <div className="field-list">
                    {fields.map((field) => (
                        <div className="field card" key={field.id}>
                            <div className="field-description">
                                <p>{field.title}<br /><span className="secondaryText">{fieldTypes.find((type) => type.id === field.type)?.description || 'Unknown Type'}</span></p>
                                <p>{field.visible ? "" : <span className="secondaryText">Feld ausgeblendet</span>}</p>
                            </div>
                            <div className="controls">
                                <span onClick={() => navigate(`/mysurveys/${surveyCode}/fields/${field.id}/edit`)}><EditIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                                <span onClick={() => openDeleteConfirmation(field)}><RemoveIcon size={"30px"} fill={"none"} color={"currentColor"} /></span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="spacer"></div>
                <div className="add-items">
                    <button onClick={() => navigate(`/mysurveys/${surveyCode}/fields/add`)} className="btn btn-primary">Neues Feld hinzufügen</button>
                </div>
                <p className="message">{message}</p>
            </div>

            {isPopupOpen && (
                <DeletePopup
                    field = {currentField}
                    onClose={() => setPopupOpen(false)}
                />
            )}

        </div>
    );
};

export default SurveyFields;
